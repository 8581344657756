import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { staticClass: "fill-height justify-center", attrs: { tag: "section" } },
    [
      _c(
        VRow,
        { attrs: { justify: "center" } },
        [
          _c(
            VSlideYTransition,
            { attrs: { appear: "" } },
            [
              _c(
                VCard,
                {
                  staticClass: "px-5 py-3",
                  attrs: {
                    color: "white",
                    light: "",
                    "max-width": "100%",
                    width: "400",
                  },
                },
                [
                  _c(VCardText, { staticClass: "text-center" }, [
                    _c("div", { staticClass: "text-center" }, [
                      _c("img", {
                        attrs: {
                          src: require("../../../assets/dxa-with-x-green.png"),
                          height: "70px",
                          alt: "DXA",
                        },
                      }),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }